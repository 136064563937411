import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import FilterStyled from './styled';
import { withRouter } from 'react-router-dom';
import { Panel } from 'react-bootstrap';
import * as filterServices from './services';
import queryString from 'query-string';
import SelectFilter from './component/selectFilter';
import { getTenantConfig } from '../../utils/utils';
import { getConfigByKey } from '../../features/tenantConfig/services';
import { isMobileOnly, isTablet, withOrientationChange } from 'react-device-detect';
import jquery from 'jquery';
import { IonIcon } from '@ionic/react';
import { filterOutline } from 'ionicons/icons';
import AdsBanner from '@/shared/components/adsBanner/views/index';
import { homePageSlugSelector } from '@/shared/features/tenantConfig/selector';

const withRouterAndRef = WrappedComponent => {
  class InnerComponentWithRef extends React.Component {
    render() {
      const { forwardRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }
  }
  const ComponentWithRouter = withRouter(InnerComponentWithRef, {
    withRef: true,
  });
  return React.forwardRef((props, ref) => {
    return <ComponentWithRouter {...props} forwardRef={ref} />;
  });
};
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      activeIDs: {},
      clearFilter: true,
      fiterData: null,
      isHasSubscription: false,
      filterOption: ['regions', 'genres', 'languages'],
    };
    this.request = 0;
    this.slugMatch = null;
    this.menuActive = null;
    this.resetActiveIDs = false;
  }

  componentDidMount() {
    const { auth } = this.props;
    this.width = window.innerWidth;
    this._initActiveIDs();
    const queryParams = queryString.parse(this.props.location.search, {
      decode: false,
    });
    this.setState({
      clearFilter: queryParams.slug ? false : this.props.resetFilter,
      isHasSubscription:
        (auth && auth.account && auth.account.profile && auth.account.profile.has_subscription) ||
        false,
    });
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillReceiveProps(nextProps) {
    const { auth } = this.props;
    const { isHasSubscription } = this.state;
    if (
      (auth &&
        auth.account &&
        auth.account.profile &&
        auth.account.profile.has_subscription !== isHasSubscription) ||
      (auth && !auth.account && isHasSubscription)
    ) {
      this.setState({
        isHasSubscription:
          (auth && auth.account && auth.account.profile && auth.account.profile.has_subscription) ||
          false,
      });
    }

    if (nextProps.match.url !== this.props.match.url) {
      const queryParams = queryString.parse(nextProps.location.search, {
        decode: false,
      });
      this.resetActiveIDs = false;
      this.nextProps = nextProps;
      this.setState({
        clearFilter: queryParams.slug ? false : this.props.resetFilter,
      });
      this._initActiveIDs();
    }

    if (nextProps.auth.isLogin === false && nextProps.auth.isLogin !== this.props.auth.isLogin) {
      this.resetActiveIDs = true;
      this.nextProps = nextProps;
      this._initActiveIDs();
    }
  }

  componentWillUnmount() {
    jquery('body').removeClass('has-content-filter');
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const { match } = this.props;
    const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
    let filterParams = this._getParamsFilter();
    if (!match.params.slug || !getConfigByKey('features.vertical_ribbon')) {
      return;
    }
    if (window.innerWidth > 992 && this.width > 992) {
      return;
    }
    if (
      filterParams &&
      Object.keys(filterParams).length === 0 &&
      filterParams.constructor === Object
    ) {
      return;
    }
    if (!isMobileOnly && isTablet) {
      this.width = window.innerWidth;
      this._initActiveIDs();
    }
  };

  _initActiveIDs = async () => {
    const { filter } = this.props;
    !getConfigByKey('_ssr') && (await this._getFilterData());
    this._getMenuItem();
    const menuActiveSlug = this.menuActive ? this.menuActive.slug : '';

    if (menuActiveSlug === '') {
      return;
    }
    const params = this._getParamsFilter(filter.activeIDs[menuActiveSlug]);
    let data = {};
    let isOpen = false;
    if (Object.keys(params).length) {
      data = filter.activeIDs[menuActiveSlug];
      isOpen = true;
    }
    this.setState(
      {
        activeIDs: data,
        isOpen,
      },
      () => {
        jquery('body').removeClass('has-content-filter');
        this._exeFilter(0);
      },
    );
  };

  _getMenuItem = (slug = '') => {
    let result = null;
    if (slug === '') {
      slug = this._getSlugMatch();
    }
    const menu = this.menu || this.props.menu;

    if (!Array.isArray(menu) || menu.length === 0) {
      return result;
    }
    if (slug === '/') {
      result = menu[0];
    } else {
      menu.some((menuItem, index) => {
        if (slug === menuItem.slug) {
          result = menuItem;
          return true;
        }
      });
    }
    this.menuActive = result;
    return result;
  };

  _getFilterData = async page => {
    const { getFilterData, menu, dpGetMenu, resetFilter } = this.props;
    const { fiterData } = this.state;
    if (resetFilter || fiterData) {
      return;
    }
    if (!menu.length) {
      await dpGetMenu()
        .then(response => {
          this.menu = response;
        })
        .catch(error => {
          console.log(error);
        });
    }

    const menuItem = this._getMenuItem(this._getSlugMatch());
    const slug = (menuItem && menuItem.slug) || '';
    if (!slug) {
      return;
    }
    getFilterData(slug)
      .then(response => {
        this.setState({
          fiterData: response,
        });
      })
      .catch(error => {});
  };

  render() {
    const { isOpen, activeIDs, isHasSubscription } = this.state;
    const { filter, t, hiddenFilter, selectBox } = this.props;
    const activeMenu = this._getMenuItem();
    if (!activeMenu) {
      return null;
    }
    let addClasses = [];
    if (isOpen === true) {
      addClasses.push('open');
    }
    if (hiddenFilter) return null;
    if (selectBox) {
      return (
        <SelectFilter
          handleToggleFilter={(event, select) => this.handleToggleFilter(event, select)}
          handleRemoveAll={() => this.handleRemoveAllSelect()}
        />
      );
    }
    return (
      <FilterStyled
        visible={this.props.visible}
        style={{ position: 'relative' }}
        props={{ isHasSubscription: isHasSubscription }}
      >
        <div className={`v-filter ${addClasses.join(' ')}`}>
          <div className="v-filter__left">
            <Panel>
              <Panel.Body>
                <button className="v-filter--toggle" onClick={this.handleToggleOpen}>
                  <IonIcon className="v-filter--icon" icon={filterOutline} />
                  {!isOpen && <p className="v-filter--title">{t('filter.filterFilm')}</p>}
                </button>
              </Panel.Body>
            </Panel>
          </div>
          <div className="v-filter__right">
            {isOpen && (
              <SelectFilter
                handleToggleFilter={(event, select) => this.handleToggleFilter(event, select)}
                handleRemoveAll={() => this.handleRemoveAllSelect()}
                handleRemoveall={this.handleRemoveall}
                filterTenant
                activeIDs={activeIDs}
              />
            )}
          </div>
        </div>
        <div className={`${!isOpen && 'v-filter__right--ads'}`}>
          <AdsBanner position="top" />
        </div>
      </FilterStyled>
    );
  }
  _uuidFilter = (type, slug) => {
    return type + '@@' + slug;
  };

  _getActiveIDs = () => {
    return this.resetActiveIDs ? {} : this.state.activeIDs;
  };
  handleToggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  handleToggleFilter = (event = false, select = false) => {
    const { setActiveIDs, dpUpdateSearch, resetFilter, history, location, filterTenant } =
      this.props;
    const slugMatch = this._getSlugMatch();
    const menuItem = this._getMenuItem(slugMatch);
    const { id, slug } = menuItem;
    let activeIDs = this._getActiveIDs();
    let el = '';
    let Dataslug = '';
    let param = '';
    let key = this._uuidFilter(param, Dataslug);
    if (event) {
      el = event.target;
      Dataslug = el.getAttribute('data-slug');
      param = el.getAttribute('data-param');
    }
    if (select) {
      Dataslug = select.slug;
      param = select.key;
    }
    let isActive = !!(activeIDs && activeIDs[param] && activeIDs[param][Dataslug]);
    this.setState(
      {
        activeIDs: {
          ...activeIDs,
          [param]: {
            // ...(activeIDs[type] ? activeIDs[type] : {}),
            [Dataslug]: !isActive,
          },
        },
        clearFilter: false,
      },
      () => {
        if ((select && this.menuActive && !filterTenant) || resetFilter) {
          const filterParams = {
            ...this._getParamsFilter(),
            id,
            slug,
          };
          if (resetFilter) {
            const queryParams = queryString.stringify(filterParams);
            let paramQuery = queryString.parse(location.search, {
              decode: false,
            });
            const locations = {
              pathname: location.pathname,
              search: `${queryParams}&tenants=${paramQuery.tenants}`,
            };
            history.replace(locations);
            return;
          }
          dpUpdateSearch(filterParams).then(() => {
            setActiveIDs({
              [this.menuActive.slug]: this._getActiveIDs(),
            });
          });
        } else {
          this._exeFilter();
        }
      },
    );
  };

  _getSlugMatch = () => {
    const { match, resetFilter, location } = this.nextProps || this.props;
    let slugMatch = '';
    if (match && match.params && match.params.slug) {
      slugMatch = match.params.slug;
    } else if (match && (match.path === '/' || match.path === '/terms-of-use')) {
      slugMatch = '/';
    }
    if (resetFilter) {
      let paramQuery = queryString.parse(location.search, { decode: false });
      slugMatch = resetFilter && paramQuery.tenants;
    }
    this.slugMatch = slugMatch;
    return slugMatch;
  };

  _exeFilter = (timeout = 1500) => {
    const { getPage, setActiveIDs, resetActiveIDs, resetFilter, homePageSlug } = this.props;
    if (this.timeoutFilter) {
      clearTimeout(this.timeoutFilter);
      this.timeoutFilter = null;
    }
    if (resetFilter) {
      return;
    }
    const slugMatch = this._getSlugMatch();
    const menuItem = this._getMenuItem(slugMatch);
    const { id, slug } = menuItem;
    let flag = true;
    if (this.width > 992) {
      flag = false;
    }
    const filterParams = {
      ...this._getParamsFilter(),
      id,
      slug,
      apply_filter_for_side_navigation_section: flag,
      limit: 10,
      homePageSlug,
    };
    this.timeoutFilter = setTimeout(() => {
      getPage(filterParams)
        .then(() => {
          if (this.resetActiveIDs) {
            resetActiveIDs();
            return;
          }
          if (this.menuActive) {
            setActiveIDs({
              [this.menuActive.slug]: this._getActiveIDs(),
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }, timeout);
  };

  _getParamsFilter = (activeIDs = null) => {
    const { clearFilter } = this.state;
    if (this.resetActiveIDs || clearFilter) {
      return {};
    }
    if (activeIDs === null) {
      activeIDs = this._getActiveIDs();
    }
    let results = {};
    Object.keys(activeIDs || {}).forEach(keyType => {
      const object = activeIDs[keyType];
      Object.keys(object).some(key => {
        if (key === 'all' || key === '') {
          return;
        }
        if (object[key] === true) {
          results[keyType] = key;
          return false;
        }
      });
    });

    return results;
  };

  handleRemoveall = event => {
    const { resetFilter, location, history } = this.props;
    let paramQuery = queryString.parse(location.search, { decode: false });
    this.setState(
      {
        activeIDs: {},
      },
      () => {
        if (resetFilter) {
          const locations = {
            pathname: location.pathname,
            search: `tenants=${paramQuery.tenants}`,
          };
          history.replace(locations);
          return;
        }
        this._exeFilter();
      },
    );
  };

  handleRemoveAllSelect = () => {
    const { dpUpdateSearch, resetActiveIDs } = this.props;
    const slugMatch = this._getSlugMatch();
    const menuItem = this._getMenuItem(slugMatch);
    const { id, slug } = menuItem;
    resetActiveIDs();
    this.setState(
      {
        activeIDs: {},
      },
      () => {
        const filterParams = {
          id,
          slug,
        };
        dpUpdateSearch(filterParams);
      },
    );
  };
}

const mapDispatchToProps = {
  getFilterData: pageSlug => filterServices.getFilterData(pageSlug),
  setActiveIDs: activeIDs => filterServices.setActiveIDs(activeIDs),
  resetActiveIDs: () => filterServices.resetActiveIDs(),
  getPage: params => filterServices.getPage(params),
  dpUpdateSearch: params => filterServices.updateSearch(params),
};

const mapStateToProps = state => ({
  menu: state?.tenantConfig?.platformConfig?.tenant_pages ?? [],
  homePageSlug: homePageSlugSelector(state),
  filter: state.filter,
  auth: state.auth,
});

export default withOrientationChange(
  withRouterAndRef(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
      withTranslation(null, { withRef: true })(Filter),
    ),
  ),
);
