import { styled } from 'twin.macro';

const DivStyled = styled.div`
  &.v-ribbon {
    &--vertical {
      margin-top: ${props => `${props.props.isSvod ? `0` : `2.8rem`}`};
    }
  }
  .v-ribbon {
    &--ads {
      width: 336px;
      height: ${props => `${props.props.isSvod ? `1rem` : `280px`}`};
    }
    &--wrap {
      &-content {
        margin-bottom: 1rem;
        margin-top: 0.55rem;
      }
    }

    &--top-content {
      height: 99%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      &-title {
        font-size: 1.25rem;
        color: #555555;
        margin-bottom: 0px;
        font-weight: bold;
      }
      &-view-more {
        color: #2574d4;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
`;

export default DivStyled;
