import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { SelectFilterStyle, ReactSelectElement } from '../styled';
import queryString from 'query-string';
import { updateHistoryFilter } from '../../../features/youtubePage/services';
import FilterHistory from '../../../features/youtubePage/component/filterHistory';
import * as constants from '../constants';

function SelectFilter(props) {
  const { t, filter, filterHistory, filterTenant, activeIDs } = props;
  const [genres, setGenres] = useState([]);
  const [languages, setLanguage] = useState([]);
  const [regions, setRegions] = useState([]);
  const [contentProviders, setContentProviders] = useState([]);
  const [peoples, setPeoples] = useState([]);
  const [historyArray, setHistoryArray] = useState(filterHistory || []);
  let paramQuery = queryString.parse(props.location.search, { decode: false });

  useEffect(() => {
    let all = [
      {
        value: t('filter.all'),
        slug: 'all',
        name: t('filter.all'),
      },
    ];
    if (Array.isArray(filter.filterData)) {
      return;
    }
    setGenres([...all, ...filter.filterData.genres]);
    setLanguage([...all, ...filter.filterData.languages]);
    setRegions([...all, ...filter.filterData.regions]);
    if (filter.filterData.content_providers)
      setContentProviders([...all, ...filter.filterData.content_providers]);
    if (filter.filterData.peoples) setPeoples([...all, ...filter.filterData.peoples]);
  }, [filter]);

  function handleChange(value, params) {
    let select = {
      key: value,
      slug: params.slug,
      name: params.name,
    };
    if (historyArray.length > 20) {
      historyArray.splice(20, historyArray.length - 20);
    }
    if (select.slug !== 'all' && !filterTenant) {
      props.dpUpdateHistoryFilter(unique([select, ...historyArray]));
      setHistoryArray(unique([select, ...historyArray]));
    }
    props.handleToggleFilter(null, select);
  }

  function unique(arr) {
    const unique = arr
      .map(e => e['slug'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  }

  const handleRemove = () => {
    if (filterTenant && props.handleRemoveall) {
      return props.handleRemoveall();
    }
    props.handleRemoveAll();
  };

  const activeSelect = (option, key) => {
    if (
      option.slug === paramQuery[key] ||
      (activeIDs && activeIDs[key] && activeIDs[key][option.slug])
    ) {
      return option.slug;
    }
    return null;
  };

  return (
    <>
      <SelectFilterStyle className={`v-select-filter ${filterTenant && 'v-select-filter-tenant'}`}>
        <div className="v-select-filter__col">
          <ReactSelectElement
            classNamePrefix={'Select'}
            className="v-select-filter-regions"
            onChange={data => handleChange('region_slug', data)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.slug}
            options={regions}
            placeholder={t('filter.regions')}
            value={regions.filter(option => activeSelect(option, 'region_slug'))}
          />
        </div>
        <div className="v-select-filter__col">
          <ReactSelectElement
            classNamePrefix={'Select'}
            className="v-select-filter-genres"
            onChange={data => handleChange('genre_slug', data)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.slug}
            options={genres}
            placeholder={t('filter.genres')}
            value={genres.filter(option => activeSelect(option, 'genre_slug'))}
          />
        </div>
        <div className="v-select-filter__col">
          <ReactSelectElement
            classNamePrefix={'Select'}
            className="v-select-filter-languages"
            onChange={data => handleChange('language_slug', data)}
            getOptionLabel={option => t(option.name)}
            getOptionValue={option => option.slug}
            options={languages}
            placeholder={t('filter.languages')}
            value={languages.filter(option => activeSelect(option, 'language_slug'))}
          />
        </div>
        {/* <div className='v-select-filter__col'>
          <ReactSelectElement
            classNamePrefix={'Select'}
            className='v-select-filter-languages'
            onChange={data => handleChange('people_slugs', data)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.slug}
            options={peoples}
            placeholder={t('filter.people')}
            value={peoples.filter(
              option => option.slug === paramQuery.people_slugs
            )}
          />
        </div>
        <div className='v-select-filter__col'>
          <ReactSelectElement
            classNamePrefix={'Select'}
            className='v-select-filter-languages'
            onChange={data => handleChange('provider_slug', data)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.slug}
            options={contentProviders}
            placeholder={t('filter.provider')}
            value={contentProviders.filter(
              option => option.slug === paramQuery.provider_slug
            )}
          />
        </div> */}
        <div className="v-select-filter__col">
          <Button onClick={() => handleRemove()}>
            <i className="fa fa-trash" /> {t('filter.removeFilter')}
          </Button>
        </div>
      </SelectFilterStyle>
      {!filterTenant ? (
        <React.Fragment>
          <hr className="v-ytbPage--hr" />
          <FilterHistory selectFilter={select => props.handleToggleFilter(null, select)} />
        </React.Fragment>
      ) : null}
    </>
  );
}

const mapStateToProps = state => ({
  filter: state.filter,
  filterHistory: state.youtubePage.filterHistory,
  page: state.home.page,
});

const mapDispatchToProps = {
  dpUpdateHistoryFilter: data => updateHistoryFilter(data),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectFilter)),
);
