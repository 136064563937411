import { styled } from 'twin.macro';
import Select from 'react-select';

const FilterStyle = styled.div`
  hr {
    /* margin: 0; */
    border-color: #c9c5c5;
  }
  .panel-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .panel {
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    background-color: ${({ theme }) => {
      return theme.body.background;
    }};
  }
  .panel-footer {
    border: none;
    background-color: transparent;
    padding-bottom: 0;
  }
  button {
    border: none;
    background-color: transparent;
  }

  .v-filter {
    display: ${props => (props.visible ? 'flex' : 'none')};
    font-size: 1rem;
    /* padding: 2em 1rem; */
    min-height: unset;
    background-color: ${({ theme }) => theme.body.background};
    &__right {
      width: 100%;
      &--ads {
        position: static;
        top: 2rem;
        right: 0;
        width: auto;
      }
    }
    &__left {
      .panel-body {
        padding-left: 0;
      }
    }
    &__items {
      .panel {
        border-left: 1px solid #c9c5c5;
      }
    }
    &--toggle {
      display: flex;
      align-items: center;
      position: relative;
      border: 1px solid #8c8c8c;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      max-height: 2.5rem;
      justify-content: space-between;
      ion-icon {
        font-size: 1.25em;
        color: ${({ theme }) => theme.filter.colorTxt};
      }
    }
    &--title {
      font-size: 1em;
      color: ${({ theme }) => theme.filter.colorTxt};
      font-weight: 400;
      margin: 0 0 0 1rem;
      white-space: nowrap;
    }

    &--list,
    &--label {
      font-size: 1em;
      color: #666666;
      padding: 0;
      font-weight: normal;
    }
    &--row,
    &--list {
      display: flex;
      margin-bottom: 0.5em;
      flex-wrap: wrap;
    }
    &--item {
      cursor: pointer;
      color: ${({ theme }) => theme.filter.colorTxt};
      transition: all 0.25s;
      padding: 0.125em 1em;
      margin-bottom: 0.25em;
      &::first-letter {
        text-transform: capitalize;
      }
      &.active {
        background-color: ${({ theme }) => theme.filter.colorBtn};
        color: white;
        border-radius: 5px;
      }
      &:first-of-type {
        cursor: default;
        pointer-events: none;
      }
    }
    &--removeall {
      color: ${({ theme }) => theme.filter.colorTxt};
      padding: 0.125em 1em;
      border-radius: 0.35em;
      border: 2px solid ${({ theme }) => theme.filter.colorTxt};
      transition: all 0.25s;
      &:active {
        background-color: ${({ theme }) => theme.filter.colorBtnActive};
        color: white;
      }
    }
    &--label {
      margin-right: 0.5em;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  &.open {
    .v-filter {
      &--toggle {
        ion-icon {
          color: ${({ theme }) => theme.filter.colorBtn};
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding-inline: 45px;
  }
  @media (min-width: 992px) {
    padding: 1em 45px;
  }
  @media (min-width: 1280px) {
    .v-filter {
      min-height: ${props => `${!props.props.isHasSubscription ? `85px` : `0`}`};
      &__right {
        &--ads {
          position: absolute;
          top: 2rem;
          right: 0;
          width: calc(100% - 15rem);
          .v-ads-banner {
            margin: 0px;
          }
        }
      }
    }
  }
`;

const SelectFilterStyle = styled.div`
  &.v-select-filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 15px;
    &-tenant {
      padding: 0 !important;
      button {
        border: 1px solid #8c8c8c;
      }
      .v-select-filter {
        &-genres,
        &-languages,
        &-regions {
          .Select__control {
            border: 1px solid #8c8c8c;
          }
          .Select__indicator {
            color: #121212 !important;
          }
        }
      }
    }
  }
  .v-select-filter {
    &__col {
      width: 45%;
      margin-right: 1rem;
      padding-bottom: 0.5rem;
      color: #333333;
      button {
        width: 100%;
      }
    }
  }
  @media only screen and (min-device-width: 319px) and (max-device-width: 375px) {
    &.v-select-filter {
      margin-top: 50px;
    }
  }
  @media only screen and (min-device-width: 374px) and (max-device-width: 767px) {
    &.v-select-filter {
      margin-top: 50px;
    }
  }
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) {
    &.v-select-filter {
      margin-top: 50px;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    &.v-select-filter {
      margin-top: 50px;
    }
  }
  @media (min-width: 768px) {
    &.v-select-filter {
      padding: 1.5rem 45px;
      justify-content: flex-start;
    }
    .v-select-filter {
      &__col {
        width: 22%;
        max-width: 270px;
        button {
          width: auto;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .v-select-filter {
      &__col {
        width: 20%;
        max-width: 270px;
      }
    }
  }
  @media (min-width: 1280px) {
    .v-select-filter {
      &__col {
        width: 15%;
        max-width: 270px;
      }
    }
  }
`;

const ReactSelectElement = styled(Select)`
  .Select__menu {
    box-shadow: 0px 0px 5px 0px #ededed;
    border-radius: 10px;
    z-index: 999;
  }
  .Select__placeholder {
    white-space: nowrap;
    font-size: 1rem;
  }
  .Select__indicator-separator {
    display: none;
  }
  .Select__value-container {
    cursor: text;
  }
  .Select__indicators,
  .Select__option {
    cursor: pointer;
  }
  &.v-select-filter-genres {
    .Select__indicator-separator {
      display: none;
    }
    .Select__menu {
      transform: translateX(-50%);
      left: 0;
      width: 90vw;
      .Select__menu-list {
        display: flex;
        flex-wrap: wrap;
        .Select__option {
          width: 33%;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .Select__placeholder {
      font-size: 0.875rem;
    }
    &.v-select-filter-genres {
      .Select__menu {
        transform: translateX(-10%);
        width: 80vw;
      }
    }
  }
  @media (min-width: 992px) {
    &.v-select-filter-genres {
      .Select__menu {
        transform: translateX(0%);
        max-width: 700px;
        .Select__menu-list {
          display: flex;
          flex-wrap: wrap;
          .Select__option {
            width: 25%;
          }
        }
      }
    }
  }
`;

export default FilterStyle;
export { SelectFilterStyle, ReactSelectElement };
