const handleResetStatus = (setRemove, props) => {
  let data = {
    isOpen: false,
    items: {},
  };
  setRemove(data);
  props.dpUpdateStatusRemove(data);
};

const handleOpen = (setRemove, props, items = {}) => {
  let data = {
    isOpen: true,
    items,
  };
  setRemove(data);
  props.dpUpdateStatusRemove(data);
};

const handleRemoveRecent = (ribbon, data, setRibbon) => {
  const items = ribbon.items;
  if (items.length === 0) {
    return;
  }
  let index = items.findIndex(item => item.id === data.items.id);
  items.splice(index, 1);
  setRibbon({ ...ribbon, items: items });
};

export { handleResetStatus, handleOpen, handleRemoveRecent };
