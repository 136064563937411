import * as apiHomePage from '@/api/homePageApi';

const getTopTen = pageSlug => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiHomePage
        .getTopTen(pageSlug)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export { getTopTen };
