import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import { Loader } from '@/shared/components/loader/Loader';
import { withTranslation } from 'react-i18next';
import { RowStyled } from '../../landingpage/styles/style';
import RibbonItem from '../../../components/ribbon/components/ribbonItem';
import { getRelatedRibbons } from '../../detailpage/services';
import { getRibbonDetail } from '../../ribbon/services';
import { withRouter } from 'react-router-dom';
import { loadMore } from '../../../utils/utils';
import queryString from 'query-string';
import RemoveItem from './removeItem';
import { handleResetStatus, handleOpen, handleRemoveRecent } from '../functions/removeItems';
import { updateStatusRemove } from '../../homepage/services';
import '../views/customise-col.scss';
import clsx from 'clsx';

function RenderLoadingOverlay(props) {
  const { isLoadingPage } = props;
  if (isLoadingPage) {
    return (
      <div className="loadingOverlayContainer">
        <div className="loadingCenterFull flex justify-center">
          <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
        </div>
      </div>
    );
  }
  return null;
}

function RenderItemRibbon(props) {
  const { value, match, season, messFilter, data, setRemove } = props;
  if (!value.id) {
    return <div className="v-season--error">{messFilter}</div>;
  }
  const itemRibbon = value.items || value.episodes;
  const params = match.params;
  let slug = params.slug;
  return itemRibbon.map((item, index) => {
    if (slug && slug === item.slug) {
      return;
    }
    return (
      <div
        className={`v-ytbPage--col ${data.fullPage}`}
        style={{ textDecoration: 'none' }}
        key={index}
      >
        <RibbonItem
          item={item}
          imgDefault={(season && season.defaultImage) || false}
          ribbon={value}
          index={index}
          handleOpen={() => handleOpen(setRemove, data, item)}
        />
      </div>
    );
  });
}

function LoaderBottom(props) {
  if (props.isLoadMore) {
    return (
      <div className="v-season--loadMore">
        <Loader type="Oval" color="#2574d4" height="40" width="40" />
      </div>
    );
  }
  return null;
}

function LeftSite(props) {
  const { t, dpGetRibbon, entityDetail, season, match, location, categoryPage } = props;
  const [searchFilter, setSearchFilter] = useState(null);
  const [ribbon, setRibbon] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isLoadMore, setLoadMore] = useState(true);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [page, setPage] = useState(0);
  const [searchUrl, setSearchUrl] = useState(null);
  const [reRender, setReRender] = useState(false);
  const [messFilter, setMessFilter] = useState(null);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [slugTenants, getSlugTenants] = useState(match.params.slug);
  const [isRemove, setRemove] = useState({ isOpen: false, items: {} });
  const pageLocation = (!categoryPage ? props.pageYtb : props.pageHome) ?? {};
  const $el = useRef(null);
  let heightDevice = null;

  function resetState() {
    setReRender(true);
    setHasMoreItems(true);
    setLoading(true);
    setPage(0);
  }

  function getRibbon() {
    let paramQuery = queryString.parse(props.location.search, { decode: false });
    if (!categoryPage && (!hasMoreItems || !paramQuery.category)) {
      return;
    }
    // slug tenantPage = homepage - ytb
    let pageSlug = paramQuery.tenants || match.params.slug;
    // id ribbon = homepage - ytb page
    let id =
      (paramQuery && paramQuery.category) ||
      (entityDetail && entityDetail.ribbonTarget.slug) ||
      match.params.slug;
    let query = `${
      !categoryPage ? `${pageLocation?.search}` : location.search
    }&page=${page}&limit=20`;
    dpGetRibbon(pageSlug, id, query)
      .then(res => {
        if (res.items.length <= 0) {
          return setHasMoreItems(false);
        }
        setMessFilter(t('notDataToShow'));
        let data = ribbon.id ? ribbon.items : [];
        data.push(...res.items);
        data = { ...res, items: data };
        setRibbon(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoadMore(false);
        setLoading(false);
        setIsLoadingPage(false);
        getSlugTenants(match.params.slug);
      });
  }

  function getRibbonFilter() {
    let paramQuery = queryString.parse(location.search, { decode: false });
    // slug tenantPage = homepage - ytb
    let pageSlug = paramQuery.tenants || match.params.slug;
    // id ribbon = homepage - ytb page
    let id =
      (paramQuery && paramQuery.category) ||
      (entityDetail && entityDetail.ribbonTarget.id) ||
      match.params.slug;
    let query = `${!categoryPage ? `${pageLocation.search}` : location.search}&page=${0}&limit=20`;
    dpGetRibbon(pageSlug, id, query)
      .then(res => {
        setRibbon(res);
        if (res.items) {
          setMessFilter(t('filter.txtFitlerNondata'));
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoadMore(false);
        setLoading(false);
        setIsLoadingPage(false);
        getSlugTenants(match.params.slug);
      });
  }

  function fetData() {
    setSearchUrl(location.search);
    setSearchFilter(pageLocation && pageLocation.search);
    if (season && season.seasonDetail) {
      setIsLoadingPage(false);
      setLoading(false);
      setRibbon(season.seasonDetail);
    }
  }

  useMemo(() => fetData(), []);

  useEffect(() => {
    heightDevice = window.scrollY + window.innerHeight;
    if (season) {
      return;
    }
    const scrollLoadData = () => {
      loadMore($el.current, heightDevice, isLoadMore)
        .then(res => {
          if (res) {
            if (!hasMoreItems) {
              setLoadMore(false);
              return;
            }
            setLoadMore(true);
            setPage(page + 1);
          }
        })
        .catch();
    };
    window.addEventListener('scroll', scrollLoadData, { passive: true });
    return () => {
      window.removeEventListener('scroll', scrollLoadData);
    };
  }, [isLoadMore, hasMoreItems, page]);

  useEffect(() => {
    if (season || reRender) {
      setReRender(false);
      setLoadMore(false);
      return;
    }
    getRibbon();
  }, [page]);

  useEffect(() => {
    let paramQuery = queryString.parse(location.search, { decode: false });
    if (searchFilter === pageLocation?.search) {
      return;
    }
    let id =
      (paramQuery && paramQuery.category) ||
      (entityDetail && entityDetail.ribbonTarget.slug) ||
      match.params.slug;
    const locations = {
      pathname: location.pathname,
      search: `category=${id}&${pageLocation?.search}`,
    };
    props.history.replace(locations);
    resetState();
    setSearchFilter(pageLocation.search);
  }, [pageLocation.search]);

  useEffect(() => {
    let passRender = false;
    if (match.params.slug !== slugTenants) {
      passRender = true;
    }
    if (!passRender && searchUrl === location.search) {
      return;
    }
    resetState();
    setSearchUrl(location.search);
    setIsLoadingPage(true);
    getRibbonFilter();
  }, [location.search, match.params.slug]);
  return (
    <RowStyled ref={$el}>
      {/* <p className="v-ytbPage--name-ribbon">{ribbon && ribbon.name}</p> */}
      {!isLoading && ribbon ? (
        <div className={clsx('v-ytbPage--row', `ribbon-${ribbon.type}`)}>
          <RenderItemRibbon
            value={ribbon}
            match={props.match}
            season={season}
            messFilter={messFilter}
            data={props}
            setRemove={setRemove}
          />
          {Object.keys(ribbon).length && !season && ribbon.items.length === 0 ? (
            <div className="col-span-full text-center">{messFilter}</div>
          ) : null}
        </div>
      ) : (
        <div
          className="v-ytbPage--loading col-span-full text-center"
          style={{ display: isLoadingPage && 'none' }}
        >
          <Loader type="Oval" color="#444444" height="30" width="30" />
        </div>
      )}
      {!isLoading && isLoadMore && <LoaderBottom isLoadMore />}
      <RenderLoadingOverlay isLoadingPage={isLoadingPage} />
      <RemoveItem
        hideModal={() => handleResetStatus(setRemove, props)}
        modalShow={isRemove.isOpen}
        data={isRemove}
        handleRemoveRecent={data => handleRemoveRecent(ribbon, data, setRibbon)}
      />
    </RowStyled>
  );
}

const mapStateToProps = state => ({
  pageYtb: state.youtubePage.page,
  pageHome: state.home.page,
});

const mapDispatchToProps = {
  dpGetRibbon: (pageSlug, ribbonSlug, search) => getRibbonDetail(pageSlug, ribbonSlug, search),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeftSite)),
);
