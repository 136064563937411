import React from 'react';
import { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as types from '../constants';

function YoutubeBadge(props) {
  if (props.videoSource) {
    return (
      <div className="v-verticalRibbon--youtubeBadge">
        <img
          loading="lazy"
          src={require('../../../assets/img/youtube.png')}
          width="80"
          height="25"
        />
      </div>
    );
  }
  return null;
}

function RibbonItemHorizontal(props) {
  const { item, t, ribbon } = props;
  let { type, released_episode_count, total_episodes } = item;
  let linkTo =
    type === types.CHANNEL_TYPE || type === types.CURATED_LIVE || item.video_source === 1
      ? `/detail/${item.slug}`
      : `/landing/${item.slug}`;
  if (item.video_source === 1 && type === types.SHOW_TYPE) {
    linkTo = `/landing/${item.slug}`;
  }
  const displayTypeStr = types[`CLASS_${ribbon.display_type || 1}`];
  let img = item.images[displayTypeStr] || item.images.thumbnail;
  let totalEps =
    released_episode_count && total_episodes
      ? `${released_episode_count}/${total_episodes} ${t('video.eps')}`
      : 'N/A';
  return (
    <DivStyled className="v-verticalRibbon" to={linkTo}>
      <div className={`v-verticalRibbon--img-wrap ${displayTypeStr}`}>
        <img className="v-verticalRibbon--thumbnail" src={img} />
        <YoutubeBadge videoSource={item.video_source} />
      </div>
      <div className="v-verticalRibbon--info">
        <p className="v-verticalRibbon--title">{item.title}</p>
        <p className="v-verticalRibbon--eps">{type === types.SHOW_TYPE ? totalEps : ''}</p>
        {/* <p className='v-verticalRibbon--update'>{item.is_new_release && 'da cap nhap tap' + item.total_episodes ? item.total_episodes + 'Tập' : ''}</p> */}
      </div>
    </DivStyled>
  );
}

const DivStyled = styled(Link)`
  &.v-verticalRibbon {
    display: flex;
    flex-direction: row;
    font-size: 1em;
    padding: 0.5rem 0;
    &:hover {
      text-decoration: none;
      .v-verticalRibbon--thumbnail {
        transform: scale(1.1);
      }
    }
  }
  .v-verticalRibbon {
    &--img-wrap {
      width: 55%;
      /* height: 162px; */
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      border: none;
      padding: 0;
      padding-top: calc(9 * 55% / 16);
      background: #fff;
      &.poster {
        padding-top: calc(40 / 27 * 55%);
      }
      &.thumbnail {
        margin-bottom: 0;
      }
    }
    &--thumbnail {
      display: block;
      max-width: 100%;
      transition: all 200ms ease-out;
      transform: scale(1);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      object-position: top;
      cursor: pointer;
    }
    &--info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 0.5rem;
      width: 45%;
      p {
        margin: 0 0 5px;
        font-size: 0.825em;
      }
    }
    &--title {
      color: #333333;
    }
    &--eps,
    &--update {
      color: #888888;
    }
    &--youtubeBadge {
      position: absolute;
      left: 0.5rem;
      width: 4.5rem;
      bottom: 0.5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
export default withTranslation()(RibbonItemHorizontal);
