import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';

function FilterHistory(props) {
  const { filterHistory, t } = props;
  const [selectedFilterHistory, setSelectedFFilterHistory] = useState(filterHistory || []);
  let viewNumber = isMobile || isTablet ? 5 : 7;
  useEffect(() => {
    setSelectedFFilterHistory(filterHistory);
  }, [filterHistory]);

  return (
    <div
      className="v-ytbPage--filter-history"
      style={{ display: !selectedFilterHistory.length && 'none' }}
    >
      <p className="v-ytbPage--filter-history-title">{t('filter.txtFilterHistory')}</p>
      {selectedFilterHistory.length &&
        selectedFilterHistory.slice(0, viewNumber).map((items, index) => {
          return (
            <Button key={index} onClick={() => props.selectFilter(items)}>
              {items.name}
            </Button>
          );
        })}
    </div>
  );
}

const mapStateToProps = state => ({
  page: state.youtubePage.page,
  filterHistory: state.youtubePage.filterHistory,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FilterHistory)),
);
